body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#root {
  min-height: stretch;
  height: -webkit-fill-available;
}

.hidden-xs {
  display: none;
}
.hidden-sm {
  display: none;
}
.hidden-md {
  display: none;
}
.hidden-lg {
  display: none;
}
.hidden-xl {
  display: none;
}

.ReactModal__Overlay {
  z-index: 999;
}

.loadingStateCheckout {
  margin: auto;
}

@media all and (min-height: 900px) {
  .historic-page {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.ReactModalPortal {
  .button-row {
    .button {
      margin: 0px 20px;
      border: 1px solid #484848;
      border-radius: 4px;
      &.last {
        border: 1px solid #484848;
        background-color: #484848;
        color: #fff;
      }
    }
  }
  .loading {
    margin: auto;
  }
}
.modal-button.mb-negative {
  background-color: #55368b;
  color: #fff;
  float: left;
}

.modal-button.mb-affirmative {
  background-color: #f0801e;
  color: #fff;
}

.modal-button {
  padding: 0;
  border: unset;
  border-radius: 4px;
  background-color: #df5a48;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  margin-top: 24px;
  cursor: pointer;
  height: 38px;
  width: 130px;
  float: right;
  &:hover{
    opacity: 0.9;
  }
  &.mb-negative {
    background-color: #55368b;
    color: #fff;
  }
  &.mb-affirmative {
    background-color: #f0801e;
    color: #fff;
  }
  &.disabled{
    background-color: #a5a5a5;
    cursor: not-allowed;
  }
  &.red {
    background-color: white;
    color: #a5a5a5;
    border: 1px solid;
    float: left;
    &:hover {
      background-color: #a5a5a5;
      color: white;
      border: 1px solid;
    }
  }
  &.mobile{
    display: block;
    border-radius: 0px;
    border-color: transparent;
    width: 100%;
    margin-top: 15px;

  }
}

.modal-text {
  color: #484848;
  text-align: left;
  margin: 16px auto;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.mobile {
  display: none;
}

@media all and (max-width: 479px) {
  .mobile {
    display: block;
  }
}

.modal-box {
  background-color: #fff;
  padding: 16px;
  position: absolute;
  z-index: 2;
  .title {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: #484848;
    font-weight: 700;
    letter-spacing: 1px;
    width: fit-content;
    margin: 0;
    margin-bottom: 24px;
  }
  .button {
    margin-top: 24px;
    padding: 0;
    border: unset;
    width: 100%;
    border-radius: 4px;
    background-color: #484848;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 12px;
    cursor: pointer;
    height: 38px;
    float: right;
    transition: 0.2s;
    &:hover {
      background-color: #484848;
    }
    &.red {
      color: #ffffff;
      background-color: #df5a48;
      &:hover {
        background-color: #fff;
        color: #df5a48;
        border: 2px solid #df5a48;
      }
    }
  }
}
.alert-box {
  top: 160px;
  width: 300px;
  left: calc(50% - 166px);
  .text {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    color: #a5a5a5;
  }
}

.form-box {
  top: 100px;
  width: 500px;
  left: calc(50% - 250px);
}
