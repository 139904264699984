body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#root {
  min-height: stretch;
  height: -webkit-fill-available;
}

.hidden-xs{
  display:none;
}
.hidden-sm{
  display:none;
}
.hidden-md{
  display:none;
}
.hidden-lg{
  display:none;
}
.hidden-xl{
  display:none;
}

.button-type1 {
  padding: 0 0 0 38px;
  margin-bottom: 16px;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 13px;
  width: 100%;
  cursor: pointer;
  height: 38px;
  position: relative;
  text-align: left;
  &.fb{
    border: unset;
    background-color: #3B5998;
    color: #fff;
    .fb-logo{
      height: 20px;
      width: 20px;
      position: absolute;
      top: 9px;
      border-radius: 4px;
      left: 8px;
      background-image: url(/public/imgs/fb-icon.png);
      background-size: contain;
    }
    &:hover{
      background-color: rgba(59, 89, 152, .8);
    }
  }
  &.google{
    border: 1px solid #a5a5a5;;
    background-color: white;
    color: #484848;
    .google-logo{
      height: 22px;
      width: 22px;
      position: absolute;
      top: 7px;
      left: 8px;
      background-image: url(/public/imgs/CO_img26.png);
      background-size: contain;
    }
    &:hover{
      background-color: rgba($color: #a5a5a5, $alpha: .2);
    }
  }
}

.bm-cross-button {
  background-color: purple;
  height: 30px;
  width: 30px;
}

input:-webkit-autofill {
  font-size: 0.8em;
  text-size: 0.8em;
}

.fs-28{
  font-size: 28px;
}
.fs-24{
  font-size: 24px;
}
.fs-20{
  font-size: 20px;
}
.fs-16{
  font-size: 16px;
}
.fs-14{
  font-size: 14px;
}
.fs-12{
  font-size: 12px;
}
.fw-bold {
  font-weight: bold;
}
.fw-600 {
  font-weight: 600;
}